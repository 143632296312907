@import "../../styles/variable.module.scss";

@mixin contentText($property, $ltr-value, $rtl-value) {
  [dir="ltr"] & {
    #{$property}: $ltr-value;
  }

  [dir="rtl"] & {
    #{$property}: $rtl-value;
  }
}

.header {
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  width: 100%;
  background: var(--background-color);
  border-bottom: 1px solid var(--border-color);

  @include mobile_device {
    &.navbarScroll {
      top: 0;
    }
  }

  @include extra_large {
    &.search_show {
      .search__mobile {
        display: flex !important;
      }
    }
    .search__mobile {
      padding: 20.5px 0;
      .header__result {
        position: relative;
        width: calc(100% - 50px);

        input {
          padding: 0 40px;
          height: 40px;
        }

        & > .icon {
          &.search {
            left: 15px;
            svg {
              height: 18px;
              width: 18px;
            }
          }
        }

        & > .icon,
        .close {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        .close {
          right: 10px;
          svg {
            height: 20px;
            width: 20px;
          }
        }
      }

      .closeSearchBar {
        width: 40px;
        height: 40px;
        box-shadow: 0 0 0 2px var(--border-color) inset;
        border-radius: 50px;
        text-align: center;
        line-height: 38px;
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }

  @include large_device {
    padding: 20px 3px;

    .search__mobile {
      padding: 0;
    }

    &.fixedClass {
      position: fixed;
    }
  }

  @include mobile_device {
    padding-left: 0;
    padding-right: 0;
  }

  & ~ main {
    padding-top: 82px;

    @include large_device {
      padding-top: 81px;
    }
  }

  .search__mobile {
    @include medium_device {
      display: none;
    }
  }

  .header__logo {
    line-height: 0;

    @include extra_small {
      height: auto;
    }

    span {
      img {
        object-fit: contain;
      }
    }
  }

  .header__search {
    .dropdownMenu {
      width: 100%;
      max-width: 300px;
      min-width: 300px;
      opacity: 1 !important;
      margin-top: 10px;
      padding: 16px !important;
      visibility: visible !important;
      border: 1px solid var(--border-color);
      max-height: 74vh;
      overflow: auto;

      @media screen and (min-width: 992px) and (max-width: 1400px) {
        max-width: 250px;
        min-width: 250px;
      }

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--border-color);
        border-radius: 50px;
      }

      @include extra_large {
        max-width: 100%;
        min-width: 100%;
        position: absolute;
        background: var(--background-color);
        -webkit-box-shadow: 0 20px 64px 0 rgba(31, 47, 70, 0.25);
        box-shadow: 0 20px 64px 0 rgba(31, 47, 70, 0.25);
        border-radius: 6px;
      }

      &::before {
        content: none;
      }

      .listItem {
        .NavSearch {
          border-bottom: 1px solid var(--border-color);
          padding-bottom: 8px;
          margin-bottom: 8px;
          margin-top: 20px;
          @include fontWeight(font-weight, 500, 700);
        }

        .searchList {
          color: $customFont;
          margin-bottom: 16px;

          img {
            border-radius: 50px;
          }

          .list {
            margin-left: 8px;

            .name {
              @include fontWeight(font-weight, 600, 700);
              color: var(--variantColor);
              line-height: normal;

              & > span {
                margin-left: 4px !important;
                vertical-align: middle;
              }
            }
          }
        }

        &:first-child {
          .NavSearch {
            margin-top: 0;
          }
        }

        &.itemList {
          .searchList {
            img {
              border-radius: 5px;
            }
          }
        }
      }

      p {
        font-size: 14px;

        a {
          color: $customFont;
          -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;

          &:hover {
            color: var(--variantColor);
          }
        }
      }
    }
  }

  .header__wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;

    .dropdownMenu,
    [class*="-menu"] {
      padding: 8px;
      position: absolute;
      border-radius: 10px;
      background-color: var(--background-color);
      -webkit-box-shadow: 0 20px 64px 0 rgb(31 47 70 / 25%);
      box-shadow: 0 20px 64px 0 rgb(31 47 70 / 25%);

      li {
        ul {
          &.upcoming {
            position: relative;
            border: 1px solid var(--border-color);
            border-radius: 8px;
            margin-top: 18px;
            padding-top: 18px;
            min-width: 180px;
            display: inline-block;

            &::before {
              @include contentText(content, "Coming Soon", "قريبا");
              @include headingFont(font-family, "DM Sans", "Almarai");
              text-align: center;
              color: $themeColor;
              font-weight: 700;
              font-size: 14px;
              line-height: 16px;
              padding: 6px 16px;
              border: 1px solid var(--border-color);
              border-radius: 50px;
              position: absolute;
              display: inline-block;
              background-color: var(--background-color);
              top: -15px;
              left: 16%;

              @include large_device {
                background: var(--light-bg);
              }
            }
          }
        }

        a {
          display: flex;
          align-items: center;
          color: var(--desc-font);
          @include fontWeight(font-weight, 500, 700);
          &:hover {
            font-weight: 700;
          }
          & > span {
            width: 16px !important;
            height: 16px !important;
            margin-right: 8px !important;
            vertical-align: middle;
          }
        }
      }

      @media screen and (min-width: 992px) {
        opacity: 0;
        visibility: hidden;
        display: block;
        transition: all 0.4s;
      }

      &:before {
        content: "";
        position: absolute;
        width: 28px;
        height: 28px;
        top: -13px;
        left: 30px;
        background: var(--background-color);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        border-radius: 8px;
        z-index: -1;
      }

      @include large_device {
        &::before {
          width: 25px;
          height: 25px;
          right: 30px;
          margin: 0 auto;
          top: -12px;
          border-radius: 6px;
          content: initial;
        }
      }

      @include large_device {
        margin-top: 30px;
        transition: all 0s;
        transform: translateY(0);

        &.show {
          visibility: visible;
          opacity: 1;
        }
      }

      @include extra_small {
        min-width: 300px;
        max-width: 300px;
        margin: 30px 0 0 -10px;

        &::before {
          right: calc(50% - 40px);
        }
      }

      .header__col {
        flex: 0 0 50%;
        padding: 0 24px;

        &:first-child {
          border-right: 1px solid var(--border-color);
        }

        .header__category {
          margin-bottom: 4px;
          font-size: 12px;
          line-height: 1.66667;
          @include fontWeight(font-weight, 500, 700);
          color: $customFont;
        }

        .header__language,
        .header__currency {
          padding: 12px 0;
          font-family: $secondaryFont;
          font-size: 14px;
          line-height: 1.14286;
          font-weight: 700;
          color: $customFont;
          cursor: pointer;
          display: block;
          position: relative;
          transition: color 0.2s;

          &[class*="disabled"] {
            pointer-events: none;
            color: #e6e8ec;
            cursor: default;
          }

          &:not(:last-child) {
            border-bottom: 1px solid var(--border-color);
          }

          &.active,
          &:hover {
            color: var(--desc-font);
          }
        }

        .header__currency {
          padding-left: 20px;

          &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            width: 8px;
            height: 8px;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            border-radius: 50%;
            background: #e6e8ec;
            transition: all 0.2s;
          }

          &:hover,
          &.active {
            &::before {
              background: #23262f;
            }
          }
        }
      }
    }

    ul {
      li {
        &:last-child {
          .navLink {
            padding: 8px 10px;
          }
        }

        &:last-child {
          .navLink {
            svg {
              position: relative;
              top: 0;
              right: 0;
              -webkit-transform: translate(0%, 0%);
              transform: translate(0%, 0%);
            }
            .NavMoreIcon {
              height: 24px;
              width: 24px;
            }
          }

          .dropdownMenu {
            @media only screen and (min-width: 992px) {
              left: -20px;
            }
          }
        }
      }
    }

    .headerItem {
      svg:not(.globalicon) {
        path {
          fill: var(--desc-font);
        }
      }
      &:hover {
        color: $themeColor !important;
        .navLink {
          color: $themeColor !important;
          svg {
            path {
              fill: $themeColor !important;
            }
          }
        }

        @media screen and (min-width: 992px) {
          .dropdownMenu {
            opacity: 1;
            visibility: visible;
            -webkit-transform: translateY(0);
            transform: translateY(0);
          }
        }
      }

      .dropdownMenu {
        @media screen and (min-width: 992px) {
          .menu {
            max-height: 225px;
            overflow: auto;
            padding: 0;

            &::-webkit-scrollbar {
              width: 5px;
            }

            &::-webkit-scrollbar-thumb {
              background-color: var(--border-color);
              border-radius: 50px;
            }
          }
        }
      }
    }

    .eventLinks {
      .headerItem {
        @include medium_device {
          background: inherit;
          padding: 0;
        }

        .navLink {
          padding: 8px 10px;

          .eventsName {
            color: inherit;

            @include large_device {
              display: inline-block;
              position: relative;
            }

            .hotLbl {
              display: inline-block;
              text-transform: capitalize;
              color: $successColor;
              line-height: 14px;
              border: 1px solid $successColor;
              border-radius: 50px;
              font-size: 10px;
              padding: 0 6px;
              vertical-align: 5px;
              position: absolute;
              top: 15px;
              right: -10px;

              @include large_device {
                top: -4px;
                right: -30px;
              }
            }
          }
        }
      }
    }

    .navLink {
      line-height: 65px;
      padding: 8px 25px 8px 10px;
      border: none;
      @include fontWeight(font-weight, 600, 700);
      &:hover {
        color: $themeColor !important;
      }
      @media screen and (min-width: 992px) and (max-width: 1400px) {
        padding: 8px 15px 8px 10px;
      }

      @include large_device {
        padding: 0;
        line-height: 0;
      }

      svg {
        position: absolute;
        top: 50%;
        -webkit-transform: translate(50%, -50%);
        transform: translate(50%, -50%);
        @media screen and (min-width: 1401px) {
          right: 14px;
        }
        @media screen and (max-width: 1400px) {
          right: 5px;
        }
      }
    }

    .header__search {
      position: relative;
      flex-shrink: 0;
      width: 300px;
      margin-left: 20px;

      @media screen and (min-width: 992px) and (max-width: 1400px) {
        width: 220px;
      }

      .icon {
        .close {
          right: 11px;
          svg {
            height: 20px;
            width: 20px;
          }
        }

        .search,
        .close {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        .search {
          left: 15px;
          .searchicon {
            height: 16px;
            width: 16px;
            cursor: pointer;
          }

          @include extra_large {
            left: 0 !important;
            right: 0 !important;
            svg {
              height: 18px;
              width: 18px;
            }
          }
        }
      }

      input {
        border-radius: 50px;
        height: 40px;
        padding: 0 35px;
        border-color: $customFont;

        @include extra_large {
          display: none;
        }
      }
    }

    .navbar {
      .navbarCollapse {
        > ul {
          > li {
            @media only screen and (min-width: 1400px) {
              margin: 0 0.39vw;
            }
          }
        }
      }
      @include large_device {
        order: 3;
        margin-left: 6px !important;
        margin-right: 0 !important;

        .navbar_toggler {
          padding: 0;
          width: 40px;
          svg {
            margin: 0 auto;

            &.navbar_expand {
              display: none;
              path {
                stroke: $lightColor;
              }
            }
          }

          &.show {
            background-color: $themeColor;
            -webkit-box-shadow: 0 0 0 2px $themeColor inset !important;
            box-shadow: 0 0 0 2px $themeColor inset !important;

            svg {
              &.navbar_expand {
                display: block;
              }

              &.collapsedToggle {
                display: none;
              }
            }
          }
          &:not(.show) {
            background: none !important;
            -webkit-box-shadow: 0 0 0 2px $customFont inset !important;
            box-shadow: 0 0 0 2px $customFont inset !important;
            color: var(--desc-font) !important;
            svg * {
              fill: $customFont;
            }
          }
        }

        .navbarCollapse {
          position: fixed;
          left: 0;
          right: 0;
          height: calc(100vh - 81px);
          background: var(--background-color);
          top: 81px;
          z-index: 5;
          width: 100%;
          display: none;

          .actionBtn {
            position: fixed;
            bottom: 0;
            align-items: center;
            justify-content: space-between;
            left: 15px;
            right: 15px;
            border-top: 1px solid var(--border-color);
            padding-top: 30px;
            z-index: 9;
            background: var(--background-color);

            & > button {
              flex: 1;

              & + button {
                margin-left: 10px;
              }
            }
          }
          & > ul {
            padding: 30px 15px 0 15px !important;
            overflow: auto;
            height: calc(100% - 100px);

            & > li {
              margin-bottom: 15px;

              &:last-child {
                margin: 0;

                button {
                  & > svg:first-child {
                    position: relative !important;
                    transform: translateY(0px) !important;
                  }
                }
              }

              .navLink {
                & > svg {
                  position: absolute;
                  right: 0;
                  top: 50%;
                  -webkit-transform: translateY(-50%) rotate(0deg);
                  transform: translateY(-50%) rotate(0deg);
                }
              }
            }

            .headerItem {
              @include large_device {
                padding-bottom: 10px;
              }

              &:hover {
                .dropdownMenu {
                  opacity: 1;
                  display: none;
                }
              }

              [aria-expanded="true"] {
                svg {
                  transform: rotate(180deg);
                }
              }
            }

            .navLink {
              display: block;
              width: 100%;
              text-align: left;
              font-size: 14px;
              line-height: 40px;
              padding: 0;

              &::after {
                right: 0;
                margin-left: 0;
              }
            }
          }
        }
      }

      .navbar_toggler {
        @include extra_small {
          width: 35px;
          height: 35px;
          line-height: 35px;
        }
      }
    }

    .actionBtn {
      align-items: center;
      position: relative;
      white-space: nowrap;
      @include medium_device {
        position: static;
      }
      .createMenu {
        & > button {
          width: auto;
          padding: 0 16px;

          &:hover {
            background: $themeHoverColor !important;
            box-shadow: 0 0 0 2px $themeHoverColor inset !important;
            color: $lightColor;
          }
        }

        .createMenuList {
          min-width: 256px;
          max-width: 256px;
          padding: 20px 16px 16px;
          margin-top: 19px;
          right: 0;
          left: auto;

          @include large_device {
            opacity: 1;
            visibility: visible;
            -webkit-transition: none;
            transition: none;
            margin-left: -30px;
          }

          &:before {
            right: 12px;
            left: auto;

            @include large_device {
              right: 40px;
            }

            @include mobile_device {
              right: calc(100% - 50%);
            }
          }

          .userName {
            @include fontWeight(font-weight, 600, 700);
            font-size: 24px;
            line-height: 32px;
            color: var(--desc-font);
            text-transform: capitalize;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .user__number {
            @include fontWeight(font-weight, 500, 700);
            font-size: 14px;
            line-height: 24px;
            color: $customFont;
            margin-bottom: 16px;

            svg {
              cursor: pointer;
            }
          }

          .balanceMenu {
            margin-bottom: 24px;
            padding: 8px;
            -webkit-box-shadow: 0 24px 24px -16px rgba(15, 15, 15, 0.2);
            box-shadow: 0 24px 24px -16px rgba(15, 15, 15, 0.2);
            border-radius: 16px;

            .balance {
              line-height: 0;

              .subTitle {
                font-size: 12px;
                line-height: 20px;
                color: $customFont;
              }

              .ethPrice {
                @include fontWeight(font-weight, 600, 700);
                font-size: 19px;
                line-height: 32px;
                color: var(--variantColor);
              }
            }
          }
        }

        .header__upload {
          &[class*="dropdown"] {
            button {
              background-color: transparent;
              border: 1px solid var(--border-color);
              -webkit-box-shadow: 0 0 4px 0 rgba(15, 15, 15, 0.2);
              box-shadow: 0 0 4px 0 rgba(15, 15, 15, 0.2);
              border-radius: 50%;
              width: 40px;
              height: 40px;

              @include extra_small {
                width: 35px;
                height: 35px;
              }
            }
          }

          &:hover {
            .createMenuList {
              @media screen and (min-width: 992px) {
                -webkit-transform: translateY(1px);
                transform: translateY(1px);
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }
      }

      .createList {
        .list {
          padding: 14px 0;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: $customFont;
          font-family: $secondaryFont;
          transition: all 0.3s;
          cursor: pointer;
          &:not(:last-child) {
            border-bottom: 1px solid var(--border-color);
          }

          &:hover {
            color: var(--variantColor);

            svg * {
              fill: var(--variantColor);
            }
          }

          [class$="-input"] {
            height: 20px;
          }
        }
      }

      @include large_device {
        order: 2;
        margin-left: auto;
        padding: 30px 0;
      }

      .header__notification {
        .Dropdown {
          position: static;
        }
        @include large_device {
          margin-right: 6px;
        }
        @include medium_device {
          margin-right: 3px;
        }

        @include extra_small {
          width: 35px;
          height: 35px;
        }
        .notbtn {
          &:hover,
          &:focus {
            background: none !important;
            box-shadow: 0 0 0 2px $customFont inset !important;
            svg * {
              fill: $text-color;
            }
          }
          .totalItems {
            position: absolute;
            top: -3px;
            right: -9px;
            background: $errorColor;
            color: $lightColor;
            min-height: 22px;
            min-width: 22px;
            border-radius: 100px;
            @include fontWeight(font-weight, 600, 700);
            font-size: 14px;
            line-height: 16px;
            &:empty {
              display: none !important;
            }
            @include medium_device {
              min-height: 18px;
              min-width: 18px;
              font-size: 10px;
              line-height: 16px;
              right: -7px;
            }
          }
        }
        .dropdownMenu {
          padding: 32px 16px;
          right: 0 !important;
          left: auto !important;
          display: none;
          min-width: 424px;
          max-width: 424px;
          -webkit-box-shadow: 0 20px 64px 0 rgb(31 47 70 / 25%);
          box-shadow: 0 20px 64px 0 rgb(31 47 70 / 25%);
          transition: all 0.4s;
          border-radius: 24px;
          opacity: 1;
          visibility: visible;
          transform: none !important;
          top: 100% !important;
          &[class*="show"] {
            display: block;
          }
          @include extra_large {
            margin-top: 19px;
          }
          @include large_device {
            opacity: 1;
            visibility: visible;
            display: none;
          }
          @include medium_device {
            min-width: calc(100% - 32px);
            max-width: calc(100% - 32px);
            opacity: 1;
            visibility: visible;
            display: none;
            left: 0 !important;
            right: 0 !important;
            margin: auto;
            padding: 20px 5px;
          }

          &:before {
            content: initial;
          }

          .title {
            margin-bottom: 16px;
            padding: 0 16px;
            @include medium_device {
              padding: 0 10px;
              margin-bottom: 12px;
            }
          }
          .noNotification {
            margin: 60px 0 30px;
            svg * {
              fill: var(--variantColor);
            }
            .title {
              margin: 10px 0 5px;
              font-size: 26px;
              font-family: inherit;
              color: var(--variantColor);
              @include fontWeight(font-weight, 600, 700);
            }
            p {
              color: var(--light-font);
            }
          }

          .userName {
            @include fontWeight(font-weight, 600, 700);
            font-size: 32px;
            line-height: 40px;
            color: var(--desc-font);
            @include medium_device {
              font-size: 24px;
              line-height: 36px;
              letter-spacing: -0.01em;
            }
          }
          .markAll {
            color: $themeColor;
            font-size: 14px;
            line-height: 16px;
            @include fontWeight(font-weight, 600, 700);
            @include medium_device {
              font-size: 12px;
              line-height: 20px;
            }
          }
          .lists {
            max-height: 55vh;
            overflow: hidden;
            overflow-y: auto;
            &::-webkit-scrollbar {
              width: 5px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: var(--border-color);
              border-radius: 50px;
            }
          }
          .see_activity {
            margin: 16px 0 0;
            button {
              width: 100%;
              margin: 0;
            }
            @include medium_device {
              padding: 0 10px;
            }
          }
          .notfList {
            position: relative;
            > a {
              padding: 16px 32px 16px 16px;
              border-radius: 16px;
              position: relative;
              transition: all 0.4s;
              @include medium_device {
                padding: 16px 10px;
              }
            }
            // &::after {
            //   content: "";
            //   width: 10px;
            //   height: 10px;
            //   background-color: $themeColor;
            //   border-radius: 50px;
            //   position: absolute;
            //   top: 50%;
            //   right: 16px;
            //   -webkit-transform: translateY(-50%);
            //   transform: translateY(-50%);
            //   @include medium_device {
            //     right: 10px;
            //   }
            // }
            &:hover {
              > a {
                background-color: var(--light-bg);
              }
            }
            .imgs {
              height: 64px;
              width: 64px;
              border-radius: 16px;
              margin-right: 24px;
              overflow: hidden;
              flex: 0 0 auto;
              @include medium_device {
                height: 60px;
                width: 60px;
                border-radius: 12px;
                margin-right: 12px;
              }
              & > div {
                & > span {
                  border-radius: 16px;
                }
              }
            }
            .details {
              white-space: normal;
              .name {
                @include fontWeight(font-weight, 500, 700);
                font-size: 16px;
                line-height: 24px;
                color: var(--variantColor);
              }

              .text1 {
                @include fontWeight(font-weight, 500, 700);
                font-size: 14px;
                line-height: 22px;
                color: var(--light-font);
              }

              .text2 {
                font-size: 12px;
                line-height: 20px;
                color: $customFont;
              }
            }
          }
        }
        .notbtn {
          padding: 0;
          margin: 0 !important;
          &::before {
            content: "";
            position: absolute;
            width: 28px;
            height: 28px;
            left: 14px;
            top: 100%;
            background: var(--background-color);
            -webkit-transform: rotate(45deg) translate3d(0px, 10px, 0px);
            transform: rotate(45deg) translate3d(0px, 10px, 0px);
            border-radius: 8px;
            z-index: 99;
            opacity: 0;
            visibility: hidden;
            @include large_device {
              content: initial;
            }
          }
          &[class*="show"] {
            &::before {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }

      .langWithCurr {
        margin-right: 5px;
        .headerItem {
          .dropdownMenu {
            padding-top: 20px;
            min-width: 340px;
            max-width: 340px;

            @media screen and (min-width: 1200px) {
              display: flex;
              right: 0;
              left: auto;
              padding: 15px 8px 8px;
            }

            &:before {
              left: auto;
              right: 30px;
            }
          }

          &:hover {
            .dropdownMenu {
              opacity: 1;
              visibility: visible;
              -webkit-transform: translateY(0);
              transform: translateY(0);

              @include extra_large {
                right: -30px !important;
              }

              @include extra_small {
                min-width: 320px;
                max-width: 320px;
                right: 30px !important;
              }
            }
          }
          .navLink {
            padding-left: 0;
            color: $customFont;
            svg * {
              fill: $customFont;
              transition: all 0.2s;
            }
          }
        }
      }

      [class^="btn"] {
        width: 40px;
        margin-left: 12px;
        @include extra_large {
          margin-left: 6px;
        }
        @include extra_small {
          width: 35px;
          height: 35px;

          .dropdownMenu {
            margin: 25px -30px 0;

            &::before {
              right: 30px;
              left: auto;
            }
          }
        }
        @include extra_large {
          svg {
            position: unset;
            -webkit-transform: translate(0px) !important;
            transform: translate(0px) !important;
          }
          .globalicon {
            height: 24px;
            width: 24px;
            path {
              fill: $customFont;
            }
          }
        }
      }
    }

    .header__link {
      font-family: $secondaryFont;
      color: $customFont;
      transition: color 0.2s;
      position: relative;
      font-size: 14px;
      padding: 8px 8px;
    }

    .headerItem {
      & > a {
        &:first-child,
        &:last-child {
          margin-right: 20px;
        }

        &::after {
          content: "";
          background-image: url("../../public/downArrow.svg");
          position: absolute;
          width: 10px;
          height: 6px;
          background-size: 10px;
          background-repeat: no-repeat;
          right: -10px;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          border: none;
          margin-left: 0;
        }
      }
    }

    .header__upload {
      margin-left: 12px;
      @include extra_large {
        margin-left: 6px;
      }
      img {
        border-radius: 100px;
      }
    }

    .changeModeIO {
      @include large_device {
        display: inline-flex;
      }

      @include extra_small {
        width: 35px;
        height: 35px;
        line-height: normal;
      }

      &:hover {
        background-color: $themeColor;
        -webkit-box-shadow: 0 0 0 2px $themeColor inset;
        box-shadow: 0 0 0 2px $themeColor inset;
      }

      & ~ .btn {
        &:hover {
          svg {
            path {
              fill: $lightColor !important;
            }
          }
        }

        .headerItem {
          .dropdownMenu {
            min-width: 340px;
            max-width: 340px;
            -webkit-transform: translateY(0);
            transform: translateY(0);

            @media screen and (min-width: 992px) and (max-width: 1199px) {
              margin-top: 6px;
            }

            @include mobile_device {
              min-width: 300px;
              max-width: 300px;
              margin-right: -30px;
            }

            &::before {
              left: auto;
              right: 5px;

              @include mobile_device {
                right: 30px;
              }
            }
          }

          &:hover {
            .dropdownMenu {
              @media screen and (max-width: 1199px) {
                opacity: 1;
                visibility: visible;
              }

              @media screen and (min-width: 992px) {
                -webkit-transform: translateY(0);
                transform: translateY(0);
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }
      }

      path {
        fill: $customFont;
      }

      &:hover {
        path {
          fill: $lightColor;
        }
      }
    }
  }
}

@include large_device {
  div {
    & ~ .header {
      .header__wrapper {
        .navbar {
          .navbarCollapse {
            top: 168px;
            height: calc(100vh - 168px);
          }
        }
      }
    }
  }
}

html .didHeader.header {
  @include medium_device{
    padding: 22.5px 3px;
  }
  .header__logo {
    .beta {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      padding: 5px 14px;
      border: 1px solid $themeColor;
      border-radius: 90px;
      color: $themeColor;
      margin: auto 0 auto 12px;
    }
  }
  .actionBtn {
    @include medium_device{
      justify-content: center !important;
    }
    [class~="btn"] {
      width: auto !important;
      min-width: 88px;
      @include medium_device{
        min-width: 140px !important;
        margin-left: 0 !important;
        height: 48px !important;
        border-radius: 90px;
        & + [class~="btn"] {
          margin-left: 20px !important;
        }
      }
    }
    [class~="buttonStroke"] {
      box-shadow: 0 0 0 1px $customFont inset !important;
      color: $customFont;
    }
  }
  .dropdownMenu {
    li {
      a {
        white-space: inherit;
      }
    }
  }
  .navbar{
    .navbarCollapse{
      > ul{
        @include large_device{
          padding: 20px 15px 0 15px !important;
          height: calc(100% - 120px);
          .eventLinks .headerItem{
            padding-bottom: 0;
            .eventsName{
              width: 100%;
            }
          }
        }
      }
    }
  }
}

[data-theme="dark"] {
  .header {
    .header__wrapper {
      .dropdownMenu {
        border: 1px solid $secondaryBg;

        &::before {
          border-left: 1px solid var(--border-color);
          border-top: 1px solid var(--border-color);
        }

        .header__col {
          .header__currency {
            &::before {
              background: $secondaryBg;
            }

            &:hover,
            &.active {
              &::before {
                background: $lightColor;
              }
            }
          }
        }
      }

      .actionBtn {
        .createMenu {
          .createMenuList {
            border: 1px solid $secondaryBg;

            &::before {
              border-left: 1px solid var(--border-color);
              border-top: 1px solid var(--border-color);
            }
          }
        }
        .header__notification {
          .notbtn {
            &::before {
              border-left: 1px solid var(--border-color);
              border-top: 1px solid var(--border-color);
            }
          }
        }
      }
    }
  }
}

[dir="rtl"] {
  .header {
    @include extra_large {
      .search__mobile {
        .closeSearchBar {
          margin-right: 10px;
          margin-left: 0;
        }
        .header__result {
          > .icon.search {
            right: 15px;
            left: auto;
          }
        }
        .close {
          left: 15px;
          right: auto;
        }
      }
    }

    .header__search {
      .dropdownMenu {
        .listItem {
          .searchList {
            .list {
              margin-right: 8px;
              margin-left: 0;

              .name {
                & > span {
                  margin-right: 4px !important;
                  margin-left: 0 !important;
                }
              }
            }
          }
        }
      }
    }

    .header__wrapper {
      .header__upload {
        margin-right: 12px;
        margin-left: 0;
        @include extra_large {
          margin-right: 6px;
        }
      }
      .navLink {
        padding: 8px 10px 8px 25px;
        @media only screen and (min-width: 992px) and (max-width: 1400px) {
          padding: 8px 10px 8px 15px;
        }
        svg {
          left: 14px;
          right: auto;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          @media screen and (max-width: 1400px) {
            left: 5px;
          }
        }
      }
      .header__search {
        margin-left: 0;
        margin-right: 20px;
        @media screen and (min-width: 1200px) {
          width: 250px;
        }
        .icon {
          .close {
            left: 11px;
            right: auto;
          }
          .search {
            right: 15px;
            left: auto;
          }
        }

        .dropdownMenu {
          @media screen and (min-width: 1200px) {
            max-width: 250px;
            min-width: 250px;
          }
          .listItem {
            .searchList {
              .list {
                .name {
                  & > span {
                    margin-right: 4px !important;
                    margin-left: 0 !important;
                  }
                }
              }
            }
          }
        }
      }

      .changeModeIO {
        & ~ .btn {
          .headerItem {
            .dropdownMenu {
              &::before {
                @include extra_large {
                  left: 5px;
                  right: auto;
                }
              }
            }
          }
        }
      }

      .actionBtn {
        @include large_device {
          margin-left: 0;
          margin-right: auto;
        }

        .createMenu {
          & > button {
            padding: 0 10px;

            @media screen and (max-width: 1400px) {
              padding: 0 6px;
              font-size: 12px;
            }
          }

          .createMenuList {
            left: 0 !important;
            right: auto !important;

            &::before {
              left: 12px;
              right: auto;
            }
          }
        }

        .langWithCurr {
          margin-right: 0;
          margin-left: 5px;
          .navLink {
            padding-left: 25px;
            padding-right: 0;
          }
          .headerItem {
            .dropdownMenu {
              right: auto;
              left: 0;

              &::before {
                left: 30px;
                right: auto;
              }

              .header__col {
                &:first-child {
                  border-right: none;
                  border-left: 1px solid var(--border-color);
                }

                .header__currency {
                  padding-left: 0;
                  padding-right: 20px;

                  &::before {
                    right: 0;
                    left: auto;
                  }
                }
              }
            }
          }
        }
        .header__notification {
          .notbtn {
            .totalItems {
              right: auto;
              left: -9px;
              @include medium_device {
                left: -7px;
              }
            }
          }
          .dropdownMenu {
            right: auto;
            left: 0;
            @include medium_device {
              right: 0;
            }
            .notfList {
              .imgs {
                margin-right: 0;
                margin-left: 24px;
              }
              &::after {
                right: auto;
                left: 16px;
              }
              > a {
                padding: 16px 16px 16px 32px;
              }
            }
          }
        }
        [class^="btn"] {
          margin-left: 0;
          margin-right: 12px;
          @include extra_large {
            margin-right: 6px;
          }
        }
      }

      ul {
        li {
          &:last-child {
            .dropdownMenu {
              left: 0;
              right: -20px;
            }
          }
        }
      }

      .navbar {
        @include large_device {
          margin-left: 0 !important;
          margin-right: 6px !important;
          .navbarCollapse {
            > ul {
              .navLink {
                text-align: right;
                > svg {
                  right: auto;
                  left: 0;
                }
              }
            }
          }
        }
      }

      .dropdownMenu,
      [class*="-menu"] {
        li {
          a {
            & > span {
              margin-left: 4px !important;
              margin-right: 0 !important;
            }
          }

          ul {
            &.upcoming {
              &::before {
                right: 16%;
                left: auto;
              }
            }
          }
        }

        .header__col {
          &:first-child {
            border-left: 1px solid var(--border-color);
            border-right: none;
          }
        }

        &::before {
          right: 30px;
          left: auto;
        }
      }

      .eventLinks {
        .headerItem {
          .navLink {
            .eventsName {
              .hotLbl {
                margin: 0 5px 0 0;
                left: -10px;
                right: auto;
              }
            }
          }
        }
      }
    }
  }
}
